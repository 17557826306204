import React from "react";
import { useSelector } from "react-redux";
import css from './BookingCount.module.css';

import totalCompletedBookingsIcon from './gg_check-o.svg'
import upcomingBookingsIcon from './wpf_future.svg'

const BookingCount = props => {
    const listingPageReducer = useSelector(state => state.ListingPage)
    const { totalCompletedBookings, upcomingBookings } = listingPageReducer

    return <div className={css.bookingCountPanel}>
        <div className={css.countWrapper}>
            <img src={totalCompletedBookingsIcon} />
            Completed bookings
            <span className={css.count}>{totalCompletedBookings}</span>
        </div>
        <div className={css.countWrapper}>
            <img src={upcomingBookingsIcon} />
            Upcoming bookings
            <span className={css.count}>{upcomingBookings}</span>
        </div>
    </div>
}

export default BookingCount