import React from "react";
import css from './SocialMediaContact.module.css'

import whatsappIcon from '../../../../assets/icons/whatsappIcon.svg'
import telegramIcon from '../../../../assets/icons/telegramIcon.svg'

const SocialMediaContact = props => {
    const { isUrl = false, text, type } = props

    const handleClick = () => {
        if (isUrl) {
            window.open(text, "_blank")
        }
    }

    const iconMapper = {
        "telegram": telegramIcon,
        "whatsapp": whatsappIcon
    }

    return <div className={css.fieldWrapper} onClick={handleClick} style={isUrl ? { cursor: 'pointer' } : {}}>
        <img src={iconMapper[type]} className={css.icon} />
        <span className={css.text}>{text}</span>
    </div>
}

export default SocialMediaContact