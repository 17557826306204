import React, { useState } from 'react';
import { Heading } from '../../components';
import { richText } from '../../util/richText';

import css from './ListingPage.module.css';

import dropdownIcon from './icons/dropdown.svg';
import collapseIcon from './icons/collapse.svg';

const MIN_LENGTH_FOR_LONG_WORDS = 20;

const SectionTextMaybeCollapsible = props => {
  const { text, heading, showAsIngress = false } = props;
  const textClass = showAsIngress ? css.ingress : css.text;
  const content = richText(text, {
    longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
    linkify: true,
    longWordClass: css.longWord,
    breakChars: '/',
  });
  const [isOpen, setIsOpen] = useState(false)
  const toggleOpen = () => {
    setIsOpen(!isOpen)
  }
  return text ? (
    <section className={css.sectionText}>
      <div className={css.collapsibleCard}>
        <div className={css.headerWrapper}>
          {heading ? (
            <Heading as="h2" rootClassName={css.sectionHeading} style={{ marginBottom: 0 }}>
              {heading}
            </Heading>
          ) : null}
          <div onClick={toggleOpen} style={{ cursor: 'pointer' }}><img src={isOpen ? collapseIcon : dropdownIcon} /></div>
        </div>
        {isOpen && <p className={textClass} style={{ marginTop: '8px' }}>{content}</p>}
      </div>
    </section >
  ) : null;
};

export default SectionTextMaybeCollapsible;
