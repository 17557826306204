import React from 'react';

import { FormattedMessage } from '../../util/reactIntl';
import { isFieldForListingType } from '../../util/fieldHelpers';

import { Heading } from '../../components';

import css from './ListingPage.module.css';
import lengthIcon from './icons/length.svg'
import areaIcon from './icons/area.svg'
import groupSizeIcon from './icons/groupSize.svg'
import seasonIcon from './icons/season.svg'
import tourIcon from './icons/tour.svg'
import typeIcon from './icons/type.svg'

const IconFieldWrapper = props => {
  const { existingListingFields, field, iconSrc, overwriteDisplay } = props
  const fieldDetails = overwriteDisplay ? overwriteDisplay : existingListingFields.find(({ key }) => key == field)

  return <div className={css.fieldWrapper}>
    <img src={iconSrc} />
    <div className={css.fieldDetails}>
      <span className={css.label}>{fieldDetails?.label}</span>
      <span className={css.value}>{fieldDetails?.value
        ? fieldDetails?.value.length < 30
          ? fieldDetails?.value
          : `${fieldDetails?.value.substring(0, 30)}...`
        : "N/A"}</span>
    </div>
  </div>
}

const SectionDetailsMaybe = props => {
  const { publicData, metadata = {}, listingFieldConfigs, config, isFieldForCategory, intl } = props;

  if (!publicData || !listingFieldConfigs) {
    return null;
  }

  const pickListingFields = (filteredConfigs, config) => {
    const { key, schemaType, enumOptions, showConfig = {} } = config;
    const listingType = publicData.listingType;
    const isTargetListingType = isFieldForListingType(listingType, config);
    const isTargetCategory = isFieldForCategory(config);

    const { isDetail, label } = showConfig;
    const publicDataValue = publicData[key];
    const metadataValue = metadata[key];
    const value = publicDataValue || metadataValue;

    if (isDetail && isTargetListingType && isTargetCategory && typeof value !== 'undefined') {
      const findSelectedOption = enumValue => enumOptions?.find(o => enumValue === `${o.option}`);
      const getBooleanMessage = value =>
        value
          ? intl.formatMessage({ id: 'SearchPage.detailYes' })
          : intl.formatMessage({ id: 'SearchPage.detailNo' });
      const optionConfig = findSelectedOption(value);

      return schemaType === 'enum'
        ? filteredConfigs.concat({ key, value: optionConfig?.label, label })
        : schemaType === 'boolean'
          ? filteredConfigs.concat({ key, value: getBooleanMessage(value), label })
          : schemaType === 'long'
            ? filteredConfigs.concat({ key, value, label })
            : filteredConfigs;
    }
    return filteredConfigs;
  };

  const existingListingFields = listingFieldConfigs.reduce(pickListingFields, []);
  const specialDisplayKeys = ["type", "length", "groupSize", "season"]

  const additionalListingFields = existingListingFields.filter(({ key }) => !specialDisplayKeys.includes(key))
  const listingTypes = config.listing.listingTypes;
  const listingTypeDetails = listingTypes?.find(({ listingType }) => listingType == publicData.listingType)
  const isBooking = listingTypeDetails?.transactionType.alias == "default-booking/release-1"

  const areaValues = publicData.area?.split(",") || []

  const valueToSelect = areaValues.length - 3 > 0
    ? areaValues.length - 3
    : areaValues.length - 2 > 0
      ? areaValues.length - 2
      : 0
  const area = areaValues[valueToSelect]
  return (
    <section className={css.sectionDetails}>
      <div className={css.customIconListingSection}>
        <IconFieldWrapper
          field="length"
          iconSrc={lengthIcon}
          existingListingFields={existingListingFields}
          overwriteDisplay={isBooking ? {
            label: "Tour length",
            value: "Selected dates"
          } : null}
        />
        <div className={css.divider}></div>
        <IconFieldWrapper
          field="season"
          iconSrc={seasonIcon}
          existingListingFields={existingListingFields}
        />
        <div className={css.divider}></div>
        <IconFieldWrapper
          iconSrc={areaIcon}
          overwriteDisplay={{
            label: 'Area',
            value: area,
          }}
        />
      </div>
      <div className={css.customIconListingSection}>
        <IconFieldWrapper
          field="groupSize"
          iconSrc={groupSizeIcon}
          existingListingFields={existingListingFields}
        />
        <div className={css.divider}></div>
        <IconFieldWrapper
          field="type"
          iconSrc={typeIcon}
          existingListingFields={existingListingFields}
        />
        <div className={css.divider}></div>
        <IconFieldWrapper
          iconSrc={tourIcon}
          overwriteDisplay={{
            label: 'Tour',
            value: listingTypeDetails?.label
          }}
        />
      </div>
      {additionalListingFields.length > 0 && <>
        <Heading as="h2" rootClassName={css.sectionHeading}>
          <FormattedMessage id="ListingPage.detailsTitle" />
        </Heading>
        <ul className={css.details}>
          {existingListingFields.map(detail => (
            <li key={detail.key} className={css.detailsRow}>
              <span className={css.detailLabel}>{detail.label}</span>
              <span>{detail.value}</span>
            </li>
          ))}
        </ul>
      </>}

    </section>
  );
};

export default SectionDetailsMaybe;
