import React from "react";
import trashIcon from './trashIcon.svg';

import css from './Keywords.module.css';
import classNames from "classnames";

const Keywords = props => {
    const { keywords, onRemoveKeyword, isEditable = false, className } = props

    return <div className={classNames(css.keywordsSection, className)}>
        {keywords.map(keyword => <div
            key={keyword}
            className={css.keywordWrapper}
        >
            <span>{keyword}</span>
            {isEditable && <div
                style={{ cursor: "pointer" }}
                onClick={() => onRemoveKeyword(keyword)}>
                &#10005;
                {/* <img src={trashIcon} style={{ width: "15px", marginBottom: "2px" }} /> */}
            </div>}
        </div>)}
    </div>

}

export default Keywords